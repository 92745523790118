<template>
  <div class="wrapper">
    <div class="background top">
      <div class="container">
        <b-row class="top">
          <p @click="activeTab = 'practicas'" :class="[activeTab === 'practicas' ? 'btn-active' : 'btn-inactive']">Prácticas en Empresa</p>
          <p @click="activeTab = 'carreras'" :class="[activeTab === 'carreras' ? 'btn-active' : 'btn-inactive']">Carreras Profesionales</p>
        </b-row>
        <div class="content">
          <div v-if="activeTab === 'practicas'">
            <b-row class="rowas">
              <b-col cols="12" class="col-md-5 img-col">
                <img loading="lazy" alt="icon" src="/images/work/candidatos.jpg" />
              </b-col>
              <b-col cols="12" class="col-md-7 text-col">
                <h3>PRÁCTICAS EN EMPRESA</h3>
                <p>
                  Bienvenido al portal de prácticas en empresas. Un portal desde el
                  que podrás acceder a la gestión de tus prácticas (ofertas publicadas,
                  actualización de tu curriculum vitae, asignación a las prácticas que
                  te interesan, seguimiento de las mismas). 
                </p>
                <p id="link-download">Descarga el Manual de Usuario</p>
                <div class="btn-red">
                  ACCEDER AL PORTAL
                  <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
                </div>
              </b-col>
            </b-row>
            <b-row class="rowas">
              <b-col cols="12" class="col-md-5 img-col">
                <img loading="lazy" alt="icon" src="/images/work/candidatos2.jpg" />
              </b-col>
              <b-col cols="12" class="col-md-7 text-col">
                <h3>¿QUIERES HACER PRÁCTICAS EN EL EXTRANJERO? PLATAFORMA HIGHERED</h3>
                <p>
                  Como consecuencia el acuerdo de colaboración con diversas agencias de
                  empleo internacionales, todos los actuales estudiantes tanto de AICAD
                  Business School o de universidades Partners, sean por estudios de
                  grado o de postgrado, tienen oportunidad de búsqueda de prácticas a
                  nivel internacional. En esta plataforma podrás explorar las posibilidades
                  que ofrecen diversas empresas para la realización de prácticas en otros
                  países, fundamentalmente del entorno europeo.
                </p>
                <div class="btn-red">
                  HIGHERED PRÁCTICAS INTERNACIONALES
                  <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
                </div>
              </b-col>
            </b-row>
          </div>
          <div v-if="activeTab === 'carreras'">
            <b-row class="rowas">
              <b-col cols="12" class="col-md-5 img-col">
                <img loading="lazy" alt="icon" src="/images/work/candidatos3.jpg" />
              </b-col>
              <b-col cols="12" class="col-md-5 text-col">
                <h3>SELECCIÓN DE PERSONAL</h3>
                <p>
                  Bienvenido al portal de Selección de Personal. Un portal exclusivamente
                  dirigido a los antiguos profesionales en búsqueda de nuevas oportunidade
                  o retos profesionales,  desde el que podrás acceder a la gestión de tu
                  <strong>carrera profesional</strong> (procesos de selección, actualización
                  de tus datos, aplicar a procesos que te interesen, seguimiento de tu
                  situación, etc.). 
                </p>
                <p id="link-download">Descarga el Manual de Usuario</p>
                <div class="btn-red">
                  ACCEDER AL PORTAl
                  <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
                </div>
              </b-col>
            </b-row>
          </div>
          <b-row>
            <p id="visit-resources">
              No te olvides de visitar la sección de recursos. En ella podrás obtener mucha
              información sobre la búsqueda de empleo, bases de datos, recursos informativos,
              etc.
            </p>
          </b-row>
        </div>
      </div>
    </div>
    <div class="background bot">
      <div class="container">
        <b-row>
          <b-col cols="12" class="col-lg-7">
            <div class="lines-access">
              <h5>¿Es la primera vez que visitas el portal?</h5>
              <p>
                Si es la primera vez que entras en el portal, te recomendamos que te
                pongas en contacto con nosotros.
              </p>
              <div class="btn-contact">
                QUIERO CONTACTAR 
                <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
              </div>
            </div>
          </b-col>
          <b-col cols="12" class="col-lg-5">
            <div class="lines-password">
              <h5>¿No tienes password?</h5>
              <font-awesome-icon :icon="['fas', 'unlock']" id="icon-unlock"/>
              <div class="btn-contact">
                QUIERO SOLICITAR MI PASSWORD
                <font-awesome-icon :icon="['fas', 'angle-double-right']"/>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      activeTab: 'practicas'
    }
  },
  metaInfo() {
    return {
      title: this.$t('empleabilidad.candidates_area'),
      meta: [
        // OpenGraph
        { vmid: "og:title", property: "og:title",  content: this.$t('empleabilidad.candidates_area') + ' - Club de Ejecutivos' },
        { vmid: "og:type", property: 'og:type', content: 'website' },
        // Google / Schema.org markup:
        { itemprop: 'name', content: this.$t('empleabilidad.candidates_area') + ' - Club de Ejecutivos' },
      ]
    }
  }
}
</script>

<style scoped lang="scss">
  .background.top {
    background: #d3d5d6;
    padding: 50px 0;
  }
  .row.top {
    border-bottom: 1px solid #1a171b;
    margin: auto;
    
    p {
      text-transform: uppercase;
      color: white;
      font-weight: 800;
      font-size: 1.1rem;
      padding: 10px;
      border-top-left-radius: 15px;
      border-top-right-radius: 15px;
      margin-right: 15px;
      cursor: pointer;
      margin-bottom: 2px;
    }

    .btn-active {
      background: #009090;
    }

    .btn-inactive {
      background: #414552;
      transition: 0.2s ease;
    }

    .btn-inactive:hover {
      background: #009090;
    }
  }

  .content {
    text-align: left;

    .rowas {
      padding: 25px 0px;
      margin: auto;

      p {
        font-size: 0.9rem;
        color: black;
      }

      img {
        width: 90%;
        margin-top: 5px;
      }

      .img-col {
        padding: 0;
      }

      .text-col {
        margin: auto 0;

        #link-download {
          text-decoration: underline;
          cursor: pointer;
        }

        #link-download:hover {
          text-decoration: none;
        }
      }

      h3 {
        font-size: 1.2rem;
        font-weight: 800;
      }

      .btn-red {
        background: #009090;
        color: white;
        text-align: center;
        padding: 10px;
        font-weight: 800;
        cursor: pointer;
        font-size: 1.2rem;
        transition: 0.2s ease;
      }

      .btn-red:hover {
        opacity: 0.7;
      }
    }

    p#visit-resources {
      font-style: italic;
      font-weight: 600;
      padding-top: 25px;
      margin: auto;
      width: 90%;
      text-align: center;
    }
  }

  .bot {
    padding: 50px 0;

    .lines-access {
      background: url('/images/work/visitar-portal-lines.png') no-repeat top center;
      height: 136px;
      width: 531px;
      margin: auto;

      p {
        font-size: 0.9rem;
        padding: 0 30px;
      }
    }

    .lines-password {
      background: url('/images/work/password-lines.png') no-repeat top center;
      height: 136px;
      width: 369px;
      margin: auto;
    }

    .btn-contact {
      font-size: 0.9rem;
      background: #3d4352;
      color: white;
      font-weight: 600;
      padding: 5px 15px;
      float: right;
      margin: 0 15px;
      cursor: pointer;
    }

    .btn-contact:hover {
      opacity: 0.7;
    }

    #icon-unlock {
      border-radius: 50%;
      border: 2px solid;
      padding: 10px;
      height: 60px;
      width: 60px;
    }
  }

  @media screen and (max-width: 991px) {
    .lines-password {
      margin: 25px auto 0 !important;
    }
  }

  @media screen and (max-width: 767px) {
    .lines-access {
      background: unset !important;
      text-align: left;
      width: unset !important;
      height: unset !important;

      .btn-contact {
        float: unset;
        text-align: center;
      }

      h5 {
        text-align: center;
      }
    }

    .lines-password {
      background: unset !important;
      width: unset !important;
      height: unset !important;

      .btn-contact {
        float: unset;
      }
    }
  }
</style>
